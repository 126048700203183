exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-planets-index-tsx": () => import("./../../../src/pages/planets/index.tsx" /* webpackChunkName: "component---src-pages-planets-index-tsx" */),
  "component---src-pages-planets-jupiter-tsx": () => import("./../../../src/pages/planets/jupiter.tsx" /* webpackChunkName: "component---src-pages-planets-jupiter-tsx" */),
  "component---src-pages-planets-mars-tsx": () => import("./../../../src/pages/planets/mars.tsx" /* webpackChunkName: "component---src-pages-planets-mars-tsx" */),
  "component---src-pages-planets-mercury-tsx": () => import("./../../../src/pages/planets/mercury.tsx" /* webpackChunkName: "component---src-pages-planets-mercury-tsx" */),
  "component---src-pages-planets-neptune-tsx": () => import("./../../../src/pages/planets/neptune.tsx" /* webpackChunkName: "component---src-pages-planets-neptune-tsx" */),
  "component---src-pages-planets-pluto-tsx": () => import("./../../../src/pages/planets/pluto.tsx" /* webpackChunkName: "component---src-pages-planets-pluto-tsx" */),
  "component---src-pages-planets-saturn-tsx": () => import("./../../../src/pages/planets/saturn.tsx" /* webpackChunkName: "component---src-pages-planets-saturn-tsx" */),
  "component---src-pages-planets-sun-tsx": () => import("./../../../src/pages/planets/sun.tsx" /* webpackChunkName: "component---src-pages-planets-sun-tsx" */),
  "component---src-pages-planets-uranus-tsx": () => import("./../../../src/pages/planets/uranus.tsx" /* webpackChunkName: "component---src-pages-planets-uranus-tsx" */),
  "component---src-pages-planets-venus-tsx": () => import("./../../../src/pages/planets/venus.tsx" /* webpackChunkName: "component---src-pages-planets-venus-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-retrograde-index-tsx": () => import("./../../../src/pages/retrograde/index.tsx" /* webpackChunkName: "component---src-pages-retrograde-index-tsx" */),
  "component---src-pages-retrograde-jupiter-tsx": () => import("./../../../src/pages/retrograde/jupiter.tsx" /* webpackChunkName: "component---src-pages-retrograde-jupiter-tsx" */),
  "component---src-pages-retrograde-mars-tsx": () => import("./../../../src/pages/retrograde/mars.tsx" /* webpackChunkName: "component---src-pages-retrograde-mars-tsx" */),
  "component---src-pages-retrograde-mercury-tsx": () => import("./../../../src/pages/retrograde/mercury.tsx" /* webpackChunkName: "component---src-pages-retrograde-mercury-tsx" */),
  "component---src-pages-retrograde-neptune-tsx": () => import("./../../../src/pages/retrograde/neptune.tsx" /* webpackChunkName: "component---src-pages-retrograde-neptune-tsx" */),
  "component---src-pages-retrograde-pluto-tsx": () => import("./../../../src/pages/retrograde/pluto.tsx" /* webpackChunkName: "component---src-pages-retrograde-pluto-tsx" */),
  "component---src-pages-retrograde-saturn-tsx": () => import("./../../../src/pages/retrograde/saturn.tsx" /* webpackChunkName: "component---src-pages-retrograde-saturn-tsx" */),
  "component---src-pages-retrograde-uranus-tsx": () => import("./../../../src/pages/retrograde/uranus.tsx" /* webpackChunkName: "component---src-pages-retrograde-uranus-tsx" */),
  "component---src-pages-retrograde-venus-tsx": () => import("./../../../src/pages/retrograde/venus.tsx" /* webpackChunkName: "component---src-pages-retrograde-venus-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

